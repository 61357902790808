import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['trix']

  change(ev) {
    [].forEach.call(ev.target.files, (file) => {
      this.trixTarget.editor.insertFile(file)
    })

    ev.currentTarget.value = null
  }
}
