import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const target = this.element.querySelectorAll(this.data.get('selector'))[0]

    if (target != null) {
      ScrollTo(target)
    }
  }
}
