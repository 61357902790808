import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { id: String }
  static targets = [ 'input' ]

  connect() {
    this.toggledElement = document.getElementById(this.idValue)

    this.inverval = setInterval(() => {
      if (this.inputTarget.checked) {
        this.toggledElement.classList.remove('d-none')
      } else {
        this.toggledElement.classList.add('d-none')
      }
    }, 10)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
