import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'formattedAddress', 'streetNumber', 'route', 'postalCode']

  connect() {
    if (google == undefined) {
      alert('Unable to load Google Maps Library')
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      this.sourceTarget, { types: ['address'] }
    )

    this.element.addEventListener('keydown', (ev) => {
      if (ev.keyCode === 13) {
        // Prevent premature form submission
        ev.preventDefault()
      }
    })

    this.sourceTarget.addEventListener('change', () => {
      // Reset any previous searches
      const place = {
        formattedAddress: '',
        streetNumber:     '',
        postalCode:       '',
        route:            ''
      }

      this.update(place)
    })

    this.autocomplete.addListener('place_changed', () => {
      const place = this.place

      this.update(place)
      this.element.submit() // Submit the form
    })
  }

  update(place) {
    Object.keys(place).forEach((key) => {
      if (this[`has${key.charAt(0).toUpperCase() + key.slice(1)}Target`]) {
        this[`${key}Target`].value = place[key]
      }
    })
  }

  get place() {
    const place = this.autocomplete.getPlace()

    if (place.address_components == undefined) {
      return {}
    } else {
      const streetNumber = place.address_components.find((component) => {
        return component.types.indexOf('street_number') > -1
      })

      const postalCode = place.address_components.find((component) => {
        return component.types.indexOf('postal_code') > -1
      })

      const route = place.address_components.find((component) => {
        return component.types.indexOf('route') > -1
      })

      return {
        formattedAddress: place?.formatted_address,
        streetNumber:     streetNumber?.long_name,
        postalCode:       postalCode?.long_name,
        route:            route?.long_name
      }
    }
  }
}
