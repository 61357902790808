import { Controller } from 'stimulus'

// Poll endpoint, replace data when http caching doesn't hit
export default class extends Controller {
  static targets = ['content']
  static values = {
    url: String,
    interval: Number
  }

  connect() {
    this.lastModified = null
    this.queueTimeout()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  queueTimeout() {
    this.timeout = setTimeout(this.poll.bind(this), this.interval)
  }

  async poll() {
    const response = await fetch(this.data.get('url'))

    if (response.ok) {
      if (this.lastModified == null) {
        this.lastModified = response.headers.get('Last-Modified')
      }

      if (response.headers.get('Last-Modified') != this.lastModified) {
        const html = await response.text()
        this.contentTarget.innerHTML = html
        this.lastModified = response.headers.get('Last-Modified')
      }
    }

    this.queueTimeout()
  }

  get interval() {
    return this.intervalValue || 2000
  }
}
