import { Controller } from 'stimulus'

import { Map, TileLayer, LatLngBounds } from 'leaflet'
import 'leaflet.heat'

export default class extends Controller {
  static values = {
    mapboxToken: String
  }

  connect() {
    this.properties = JSON.parse(this.data.get('properties')).properties

    this.bounds = this.properties.reduce((bounds, property) => {
      return bounds.extend(property.location)
    }, new LatLngBounds())

    this.setupMap()
  }

  setupMap() {
    this.map = new Map(this.element, {
      zoom: 3,
      center: { lat: -28.024, lng: 140.887 },
      zoomControl: false, zoomAnimation: false, fadeAnimation: false,
      dragging: false, scrollWheelZoom: false
    })

    this.map.fitBounds(this.bounds)

    const tileLayer = new TileLayer(
      'https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}@2x.png?key=RpT1IUUobUwtOyT3JhMr'
    )

    tileLayer.addTo(this.map)
    tileLayer.on('load', () => this.element.classList.add('loaded'))

    const radiusFactor = 20.0 / this.map.getZoom()

    new L.HeatLayer(
      this.properties.map((property) => [property.location[0], property.location[1], property.weight]),
      { radius: 11 + (11 * radiusFactor), blur: this.map.getZoom() }
    ).addTo(this.map)
  }
}
