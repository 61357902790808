import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this._watchForRegister()
  }

  disconnect() {
    this._adapter.clearWatch(this.watcherId)

    document.removeEventListener('location-manager:enable', this.registrationHandler)
  }

  watchForRegisterListenerHandler() {
    this.watcherId = this._adapter.watchPosition(
      (position) => this._updateLocation(position),
      (err) => this._errorObtainingLocation(err),
      {
        enableHighAccuracy: true,
        maximumAge:         5000
      }
    )

    if (this.error == undefined) {
      this._emitLastLocationEvent()
    } else {
      this._emitErrorObtainingPosition()
    }
  }

  _updateLocation(position) {
    this.lastPosition = position
    this._emitLastLocationEvent()
  }

  _errorObtainingLocation(error) {
    console.log(error)
    this.error = error
    this._emitErrorObtainingPosition()
  }

  _watchForRegister() {
    this.registrationHandler = (event) => this.watchForRegisterListenerHandler(event)
    document.addEventListener('location-manager:enable', this.registrationHandler)
  }

  _emitLastLocationEvent() {
    if (this.lastPosition == undefined)
      return

    const detail = { position: this.lastPosition }
    const event = new CustomEvent('position:update', { detail: detail })
    document.dispatchEvent(event)
  }

  _emitErrorObtainingPosition() {
    const detail = { error: this.error }
    const event = new CustomEvent('position:error', { detail: detail })
    document.dispatchEvent(event)
  }

  get _adapter() {
    return window.deviceGeolocation || window.navigator.geolocation
  }
}
