import { Controller } from 'stimulus'

import mapboxgl from '!mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher'
import bbox from '@turf/bbox'
import intersect from '@turf/intersect'
import { uniqBy, debounce } from 'lodash'
import pLimit from 'p-limit'

export default class extends Controller {
  static states = {
    default: 'rgba(171, 171, 171, 0.15)',
    adding: 'rgba(48, 151, 209, 0.2)',
    added: 'rgba(32, 201, 151, 0.3)',
    error: 'rgba(220, 53, 69, 0.3)'
  }
  static targets = ['map']
  static values = {
    mapboxToken: String,
    landgridToken: String,
    center: Array,
    propertyCreatePath: String,
    propertyDestroyPath: String,
    csrfToken: String,
    alreadyAddedParcelNumbers: Array
  }

  connect() {
    this.adding = new Set()
    this.added = new Set()
    this.drawing = false
    this.center = this.centerValue
    this.zoom = 16
    this.addQueue = pLimit(3)

    if (this.avg(this.center) == 0.0) {
      navigator.geolocation.getCurrentPosition(
        this.locationDetected.bind(this),
        this.locationDetectionFailed.bind(this)
      )
    } else {
      this.setupMap()
    }
  }

  locationDetected(position) {
    this.center = [position.coords.longitude, position.coords.latitude]
    this.setupMap()
  }

  locationDetectionFailed() {
    this.center = [-76.8394, 39.2404]
    this.zoom = 13
    this.setupMap()
  }

  setupMap() {
    mapboxgl.accessToken = this.mapboxTokenValue
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.center,
      zoom: this.zoom, doubleClickZoom: !this.touchable
    })

    const nav = new mapboxgl.NavigationControl({
      showCompass: false,
    })

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })

    this.map.addControl(new MapboxStyleSwitcherControl(), 'top-left')
    this.map.addControl(nav, 'top-left')
    this.map.addControl(geocoder, 'top-right')
    this.map.on('styledata', this.addLayers.bind(this))
    this.map.on('load', this.mapLoad.bind(this))

    if (!this.touchable) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        touchEnabled: false,
        controls: {
          polygon: true
        }
      })

      this.map.addControl(this.draw)
    }
  }

  addLayers() {
    if (this.map.getSource('parcels')) return

    this.map.addSource('parcels', {
      type: 'vector',
      tiles: [`https://tiles.makeloveland.com/api/v1/parcels/{z}/{x}/{y}.mvt?token=${this.landgridTokenValue}`],
      promoteId: 'parcelnumb'
    })

    this.map.addLayer({
      id: 'parcels', type: 'fill', source: 'parcels', 'source-layer': 'parcels',
      minzoom: 13, layout: { visibility: 'visible' },
      paint: {
        'fill-outline-color': 'rgba(171, 171, 171, 0.5)',
        'fill-color': ['string', ['feature-state', 'color'], this.constructor.states.default]
      }
    })
  }

  mapLoad() {
    if (this.touchable) {
      this.map.on('touchstart', 'parcels', this.touchStart.bind(this))
      this.map.on('touchend', 'parcels', this.touchEnd.bind(this))
    } else {
      this.map.on('click', 'parcels', this.parcelClick.bind(this))
    }

    this.map.on('draw.create', this.drawPolygonCreate.bind(this))
    this.map.on('draw.modechange', this.drawModeChange.bind(this))

    this.alreadyAddedParcelNumbersValue.forEach((id) => {
      this.added.add(id)
    })

    this.map.on('sourcedata', debounce(this.markFeatures.bind(this), 30))

    this.markFeatures()
  }

  markFeatures() {
    console.log('remarking features')

    this.markFeaturesWithColor([...this.added], this.constructor.states.added)
    this.markFeaturesWithColor([...this.adding], this.constructor.states.adding)
  }

  touchStart(ev) {
    this.lastTouch = ev
  }

  touchEnd(ev) {
    if (this.lastTouch?.point?.x == ev.point.x && this.lastTouch?.point?.y == ev.point.y) {
      console.log('touch as click')
      this.parcelClick(ev)
    }

    this.lastTouch = null
  }

  parcelClick(ev) {
    if (this.drawing) {
      return
    }

    const feature = ev.features[0]
    this.toggleFeature(feature)
  }

  drawModeChange(ev) {
    if (ev.mode === 'draw_polygon') {
      console.log('started drawing')
      this.drawing = true
    }
  }

  drawPolygonCreate(ev) {
    // Wait until next tick before turning off drawing
    // Otherwise the click handler will fire
    requestAnimationFrame(() => this.drawing = false)

    const userPolygon = ev.features[0]
    const polygonBoundingBox = bbox(userPolygon)
    const southWest = [polygonBoundingBox[0], polygonBoundingBox[1]]
    const northEast = [polygonBoundingBox[2], polygonBoundingBox[3]]

    const northEastPointPixel = this.map.project(northEast)
    const southWestPointPixel = this.map.project(southWest)

    const features = this.map.queryRenderedFeatures(
      [southWestPointPixel, northEastPointPixel],
      { layers: ['parcels'] }
    ).filter((feature) => {
      return intersect(feature, userPolygon) != null
    })

    const uniqFeatures = uniqBy(features, (feature) => feature.id)

    if (uniqFeatures.length <= 100) {
      uniqFeatures.forEach(this.addFeature.bind(this))
    } else {
      alert(
        `Uh oh! You've selected too many properties at once. You selected ${uniqFeatures.length} \
properties; we only allow selecting up to 100 proerties at a time. Please try selecting a smaller area.`
      )
    }

    this.draw.deleteAll()
  }

  addFeature(feature) {
    if ([undefined, null].indexOf(feature.id) > -1) {
      return
    }

    if (!this.added.has(feature.id) && !this.adding.has(feature.id)) {
      this.adding.add(feature.id)
      this.map.setFeatureState(
        feature,
        {
          color: this.constructor.states.adding
        }
      )

      this.addQueue(() => this.createProperty(feature))
    }
  }

  toggleFeature(feature) {
    if ([undefined, null].indexOf(feature.id) > -1) {
      return
    }

    if (this.added.has(feature.id)) {
      this.removeProperty(feature.id)
    } else if (!this.adding.has(feature.id)) {
      this.addFeature(feature)
    } else {
      console.log('Some other feature state?')
    }
  }

  markFeatureWithColor(id, color) {
    this.map.queryRenderedFeatures({ layers: ['parcels'], filter: ['match', ['get', 'parcelnumb'], id, true, false] })
        .forEach((feature) => {
          if (feature.state.color !== color) {
            this.map.setFeatureState(
              feature,
              {
                color: color
              }
            )
          }
        })
  }

  markFeaturesWithColor(ids, color) {
    if (ids.length === 0) {
      return
    }

    this.map.queryRenderedFeatures({ layers: ['parcels'], filter: ['match', ['get', 'parcelnumb'], ids, true, false] })
        .forEach((feature) => {
          if (feature.state.color !== color) {
            this.map.setFeatureState(
              feature,
              {
                color: color
              }
            )
          }
        })
  }

  async createProperty(feature) {
    const featureBox = bbox(feature)
    const longitude = this.avg([featureBox[0], featureBox[2]])
    const latitude = this.avg([featureBox[1], featureBox[3]])

    try {
      const response = await fetch(this.propertyCreatePathValue, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfTokenValue
        },
        body: JSON.stringify({
          parcel_number: feature.id, path: feature.properties.path,
          latitude: latitude, longitude: longitude
        })
      })

      const json = await response.json()

      if (response.ok) {
        this.adding.delete(feature.id)
        this.markFeatureWithColor(feature.id, this.constructor.states.added)
        this.added.add(feature.id)
        window.toastr.success(json.mailing_name, `${json.street_1} added!`, {timeOut: 2000})
      } else {
        console.log(json)
        this.adding.delete(feature.id)
        this.markFeatureWithColor(feature.id, this.constructor.states.error)
        window.toastr.error(`Problem adding property: ${json.join(', ')}`, '', {timeOut: 2000})
      }
    } catch(error) {
      window.toastr.error('Problem adding property', '', {timeOut: 2000})
      throw(error)
    }
  }

  async removeProperty(id) {
    this.adding.delete(id)
    this.added.delete(id)

    try {
      const response = await fetch(this.propertyDestroyPathValue.replace(':id', id), {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfTokenValue
        }
      })

      const json = await response.json()

      if (response.ok) {
        this.markFeatureWithColor(id, this.constructor.states.default)
        window.toastr.warning(`${json.street_1} removed!`, '', {timeOut: 2000})
      } else {
        this.markFeatureWithColor(id, this.constructor.states.error)
        window.toastr.error(`Unable to remove ${json.street_1}`, '', {timeOut: 2000})
      }
    } catch(error) {
      this.markFeatureWithColor(id, this.constructor.states.error)
      window.toastr.error('Problem removing property', '', {timeOut: 2000})
      throw(error)
    }
  }

  avg(array) {
    return array.reduce((a, b) => a + b) / array.length
  }

  get touchable() {
    return window.ontouchstart !== undefined
  }
}
