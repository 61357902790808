import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['anchor', 'pane']

  connect() {
    if (this.hash.split('-')[0] == this.element.id) {
      const targetEl = this.element.querySelector(`a[href="#${this.hash.split('-')[1]}"]`)
      if (targetEl) {
        this.switchTo(targetEl)
      } else {
        this.switchTo(this.anchorTargets[0])
      }
    } else {
      this.switchTo(this.anchorTargets[0])
    }
  }

  nav(ev) {
    ev.preventDefault()
    this.switchTo(ev.currentTarget)
  }

  switchTo(targetEl) {
    const targetId = targetEl.getAttribute('href').replace('#', '')

    this.anchorTargets.forEach((el) => el.classList.remove('active'))
    targetEl.classList.add('active')

    this.resetForm()
    this.paneTargets.forEach((el) => el.classList.remove('active'))
    this.paneTargets.find((el) => el.id == targetId).classList.add('active')

    this.paneTargets.forEach((el) => {
      if (el.classList.contains('active')) {
        this.enableRequiredInputs(el)
      } else {
        this.disableRequiredInputs(el)
      }
    })

    const url = new URL(window.location)
    url.hash = [this.element.id, targetId].join('-')
    window.history.replaceState({}, targetEl.getAttribute('title'), url)
  }

  enableRequiredInputs(parent) {
    parent.querySelectorAll('input[data-required]').forEach((el) => {
      el.setAttribute('required', 'true')
    })
  }

  disableRequiredInputs(parent) {
    parent.querySelectorAll('input[required]').forEach((el) => {
      el.setAttribute('data-required', 'true')
      el.removeAttribute('required')
    })
  }

  resetForm() {
    this.element.closest('form')?.reset()
  }

  get hash() {
    return window.location.hash.replace('#', '')
  }
}
