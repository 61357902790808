import { Controller } from 'stimulus'

import { Map, Icon, TileLayer, Marker } from 'leaflet'

export default class extends Controller {
  connect() {
    this.properties = JSON.parse(this.data.get('properties'))
    this.routeAddresses = JSON.parse(this.data.get('routeAddresses'))
    this.setupMap()
    this.addMarkers()
    this.propertyCreateListener = (ev) => { this.propertyCreated(ev) }
    this.propertyCheckListener = (ev) => { this.propertyCheck(ev) }
    document.addEventListener('onboarding:property:create', this.propertyCreateListener)
    document.addEventListener('onboarding:property:check', this.propertyCheckListener)
  }

  disconnect() {
    document.removeEventListener('onboarding:property:create', this.propertyCreateListener)
    document.removeEventListener('onboarding:property:check', this.propertyCheckListener)
  }

  setupMap() {
    this.map = new Map(this.element, {
      zoomControl: true, zoomAnimation: true, fadeAnimation: true
    })

    try {
      this.map.fitBounds(this.bounds, { padding: [30, 30] })
    } catch(error) {
      console.error(error)
    }

    new TileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/satellite-streets-v11',
        accessToken: this.data.get('mapboxToken')
      }
    ).addTo(this.map)
  }

  addMarkers() {
    this.markers = {}

    this.routeAddresses.forEach((routeAddress) => {
      const marker = new Marker(
        Object.values(routeAddress.location),
        { icon: this.routeAddressIcon, title: routeAddress.label }
      )
      marker.on('click', (ev) => {
        const event = new CustomEvent('maps:markerClicked', {
          detail: { event: ev, marker: routeAddress }, bubbles: true
        })

        this.element.dispatchEvent(event) // Keep this semicolon
      })
      marker.addTo(this.map)
      this.markers[routeAddress.id] = marker
    })

    this.properties.forEach((property) => {
      const marker = new Marker(
        Object.values(property.location),
        { icon: this.propertyIcon, title: property.label }
      )
      marker.addTo(this.map)
    })
  }

  propertyCreated(ev) {
    const marker = this.markers[ev.detail]

    if (marker) {
      marker.setIcon(this.propertyIcon)
    }
  }

  propertyCheck(ev) {
    const marker = this.markers[ev.detail.slug]
    const color = ev.detail.checked ? 'grey' : 'grey-faded'

    if (marker) {
      const icon = this.icon(color)
      marker.setIcon(icon)
    }
  }

  get propertyIcon() {
    return this.icon('blue')
  }

  get routeAddressIcon() {
    return this.icon('grey')
  }

  icon(color) {
    return new Icon({
      iconUrl: this.data.get(`${color}-pin-url`),
      iconSize: [30, 30]
    })
  }

  get coords() {
    return this.propertyCoords + this.routeAddressesCoords
  }

  get bounds() {
    if (this.routeAddresses.length == 0) {
      return this.propertyCoords
    } else {
      return this.routeAddressesCoords
    }
  }

  get propertyCoords() {
    return this.properties.map((property) => Object.values(property.location))
  }

  get routeAddressesCoords() {
    return this.routeAddresses.map((routeAddress) => Object.values(routeAddress.location))
  }
}
