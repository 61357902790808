import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['contents']

  connect() {
    this.toggle()

    this.element.addEventListener('toggle', () => this.toggle())
  }

  toggle() {
    if (this.element.open) {
      if (this.tmpl) {
        this.contentsTarget.innerHTML = this.tmpl.innerHTML
        this.tmpl.remove()
        this.tmpl = undefined
      }
    } else {
      this.tmpl = document.createElement('template')
      this.tmpl.innerHTML = this.contentsTarget.innerHTML
      this.element.appendChild(this.tmpl)
      this.contentsTarget.innerHTML = ''
    }
  }
}
