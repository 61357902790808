import { Controller } from 'stimulus'

export default class extends Controller {
  static loadingTmpl = '<div class="text-center" id="loading"> <div class="card p-5 m-3"> <h2> Generating PDF... </h2> <div class="sk-folding-cube"> <div class="sk-cube1 sk-cube"> <div class="animation-element"></div> </div> <div class="sk-cube2 sk-cube"> <div class="animation-element"></div> </div> <div class="sk-cube4 sk-cube"> <div class="animation-element"></div> </div> <div class="sk-cube3 sk-cube"> <div class="animation-element"></div> </div> </div> <p class="text-center"> Started <span id="duration"></span>... </p> </div> </div>'
  connect() {
    this.element.addEventListener('click', (event) => this.click(event))
  }

  click(event) {
    event.preventDefault()
    document.body.insertAdjacentHTML('beforeend', this.constructor.loadingTmpl)

    const ajaxRequest = new XMLHttpRequest()

    ajaxRequest.addEventListener('load', (loadEvent) => {
      const loadingEl = document.getElementById('loading')
      loadingEl.parentElement.removeChild(loadingEl)

      window.location = loadEvent.currentTarget.responseURL
    })

    ajaxRequest.open('GET', this.element.getAttribute('href'))
    ajaxRequest.send()
  }
}
