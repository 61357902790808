import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.locations = JSON.parse(this.data.get('locations'))

    this.buildMarkers()
    this.setupMap()
    this.addMarkers()
  }

  setupMap() {
    this.map = new google.maps.Map(this.element, {
      zoom: 3,
      center: { lat: -28.024, lng: 140.887 },
      mapTypeId: 'roadmap',
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      }
    })

    this.map.setTilt(0)

    const bounds = this.markers.reduce((bounds, marker) => {
      return bounds.extend(marker.getPosition())
    }, new google.maps.LatLngBounds())

    try {
      this.map.setCenter(bounds.getCenter())
      this.map.fitBounds(bounds)
    } catch(error) {
      console.error(error)
    }
  }

  buildMarkers() {
    this.markers = this.locations.filter((location) => {
      return location.location != undefined
    }).map((location) => {
      return new google.maps.Marker({
        position: location.location,
        title: location.label
      })
    })
  }

  addMarkers() {
    if (this.data.get('cluster') === 'true') {
      new MarkerClusterer(this.map, this.markers, {
        imagePath: '/markers/m'
      })
    } else {
      this.markers.forEach((marker) => {
        requestAnimationFrame(() => {
          marker.setAnimation(google.maps.Animation.DROP)
          marker.setMap(this.map)
        })
      })
    }
  }
}
