import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', () => {
      if (this.shouldConfirm) {
        const didConfirm = confirm(this.data.get('text'))

        this.element.checked = this.result(didConfirm)
      }
    })
  }

  get shouldConfirm() {
    if (this.data.get('orientation') == 'checked') {
      return this.element.checked
    } else {
      return false
    }
  }

  // Determine if the checkbox should be check or not
  result(didConfirm) {
    if (this.data.get('orientation') == 'checked') {
      return didConfirm
    } else {
      return false
    }
  }
}
