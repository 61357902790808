import { Controller } from 'stimulus'
import { appendDefaultEditor, createDefaultImageWriter } from '../../lib/pintura'

export default class extends Controller {
  connect() {
    document.addEventListener('photo-editor:edit', this.editRequest.bind(this))
  }

  disconnect() {
    document.removeEventListener('photo-editor:edit', this.editRequest)
  }

  editRequest(ev) {
    this.element.removeAttribute('hidden')

    const defaultEditorDetails = {
      imageWriter: createDefaultImageWriter({
        copyImageHead: false
      })
    }

    const resultElement = ev.srcElement
    const editor = appendDefaultEditor(this.element, Object.assign(defaultEditorDetails, ev.detail))

    editor.on('process', (ev) => {
      const detail = {}

      if (editor.history.length > 1) {
        // Something actually happened
        detail.file = ev.dest
      }

      const event = new CustomEvent('photo-editor:done', { bubbles: false, detail: detail })
      resultElement.dispatchEvent(event)

      editor.destroy()
      this.element.setAttribute('hidden', true)
    })
  }
}
