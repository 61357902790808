const fontRatio = 26/1000
const canvas = document.createElement('canvas')

function blobToImg(blob) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()

    img.onload = () => resolve(img)
    img.onerror = (error) => reject(error)

    reader.readAsDataURL(blob)

    reader.onload = function() {
      img.src = reader.result
    }
  })
}

function _readFileAsCanvas(blob) {
  return new Promise((resolve, reject) => {
    const ctx = canvas.getContext('2d', { alpha: false })

    blobToImg(blob).then((img) => {
      canvas.width = img.width
      canvas.height = img.height

      ctx.drawImage(img, 0, 0)
      resolve()
    }).catch(reject)
  })
}

function addOverlay(blob, txt) {
  return new Promise((resolve, reject) => {
   _readFileAsCanvas(blob).then(() => {
      const ctx            = canvas.getContext('2d')
      const fontSize       = canvas.height * fontRatio

      ctx.font = fontSize + 'px monospace'
      ctx.fillStyle = 'rgba(0,0,0,0.5)'

      const txtWidth = ctx.measureText(txt).width

      ctx.fillRect(canvas.width - txtWidth - 4, canvas.height - fontSize - 4, txtWidth + 4, fontSize + 4)
      ctx.fillStyle = 'rgba(255,255,255,1)'
      ctx.fillText(txt, canvas.width - txtWidth - 2, canvas.height - 5)

      canvas.toBlob(resolve, 'image/jpeg', 1)
    }).then(() => {
      canvas.width = 1
      canvas.height = 1
      console.log('done overlay')
    }).catch(reject)
  })
}

export default addOverlay
