import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { latitude: Number, longitude: Number, title: String }

  connect() {
    const markerLatLng = new google.maps.LatLng(
      this.latitudeValue, this.longitudeValue
    )

    this.map = new google.maps.Map(this.element, {
      zoom:      20,
      center:    markerLatLng,
      mapTypeId: 'hybrid',
      mapTypeControl: false,
      streetViewControl: false
    })

    this.map.setTilt(0)

    this.marker = new google.maps.Marker({
      position: markerLatLng,
      map:      this.map,
      title:    this.titleValue
    })
  }
}
