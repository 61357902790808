const Locationable = (superclass) => class extends superclass {
  disconnect() {
    this.tearDown()
  }

  tearDown() {
    this.stopListenForLocationChange()

    if (this.listenTimeout) {
      clearTimeout(this.listenTimeout)
    }
  }

  listenForLocationChange(timeout) {
    if (timeout != undefined) {
      this.listenTimeout = setTimeout(() => {
        if (this.receivedPosition == undefined) {
          this.positionError(Error('Position timeout'))
        }
      }, timeout)
    }

    this.positionListenerHandler = (event) => {
      if (event.detail.position != undefined) { this.positionUpdate(event) }
    }

    this.positionErrorListenerHandler = (event) => this.positionError(event)

    document.addEventListener('position:update', (event) => {
       if (event.detail.position != undefined) { this.receivedPosition = event }
    })

    document.addEventListener('position:update', this.positionListenerHandler)
    document.addEventListener('position:error', this.positionErrorListenerHandler)

    const event = new CustomEvent('location-manager:enable')
    document.dispatchEvent(event)
  }

  stopListenForLocationChange() {
    document.removeEventListener('position:update', this.positionListenerHandler)
    document.removeEventListener('position:error', this.positionErrorListenerHandler)
  }

  positionAsObject(position) {
    return {
      coords: {
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        speed: position.coords.speed
      },
      timestamp: new Date(position.timestamp)
    }
  }

  positionUpdate(_ev) {}
  positionError(_ev) {}
}

export default Locationable
