import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filterInput', 'filteredElements']

  filter() {
    const value = this.filterInputTarget.value.toString().toLowerCase()

    this.filteredElementsTargets.forEach((element) => {
      if (element.innerText.toLowerCase().match(value)) {
        element.removeAttribute('hidden')
      } else {
        element.setAttribute('hidden', true)
      }
    })
  }

  clearFilter() {
    this.filterInputTarget.value = ''
    this.filter()
    this.filterInputTarget.blur()
  }
}
