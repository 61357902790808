import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['map']
  static values = { googleKey: String, latitude: String, longitude: String }

  connect() {
    const url = this.url
    url.searchParams.append('key', this.googleKeyValue)
    url.searchParams.append('markers', `${this.latitudeValue},${this.longitudeValue}`)
    url.searchParams.append('size', `${this.element.offsetWidth}x${this.element.offsetHeight}`)
    url.searchParams.append('zoom', 20)
    url.searchParams.append('maptype', 'hybrid')
    url.searchParams.append('format', 'jpg')

    this.element.insertAdjacentHTML(
      'beforeend',
      `<img width="100%" src="${url.toString()}">${this.replaceWithMapElement}`
    )
  }

  replaceWithMap(_ev) {
    if (this.hasMapTarget) {
      const map = this.mapTarget.content.cloneNode(true)
      this.element.parentElement.replaceChild(map, this.element)
    }
  }

  get replaceWithMapElement() {
    if (this.hasMapTarget) {
      return '<div class="replace-with-map"><button><i class="fas fa-map-marked-alt"></i> Interactive Map</button></div>'
    } else {
      return ''
    }
  }

  get url() {
    return new URL('/maps/api/staticmap', 'https://maps.googleapis.com')
  }
}
