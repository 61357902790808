import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('toggle', () => this.updateState(this.element.open))

    if (this.persistedSate == 'true') {
      this.element.setAttribute('open', true)
    } else if (this.persistedSate === 'false') {
      this.element.removeAttribute('open')
    }
  }

  updateState(value) {
    window.localStorage.setItem(this.element.id, value)
  }

  get persistedSate() {
    return window.localStorage.getItem(this.element.id)
  }
}
