import GraphController from './graph_controller'
import ColorMap from '../../helpers/color_map'

export default class extends GraphController {
  get graphData() {
    let otherCount = 0
    const data = {
      datasets: [
        {
          fill: false,
          data: [],
          backgroundColor: [],
          label: this.chartLabel
        }
      ],
      labels: []
    }

    this.json().sort((a, b) =>  a.count < b.count).forEach((violationCategory, i) => {
      if (i < 5) {
        data.datasets[0].data.push(violationCategory.count)
        data.labels.push(violationCategory.title)
      } else {
        otherCount += violationCategory.count
      }
    })

    if (otherCount > 0) {
      data.datasets[0].data.push(otherCount)
      data.labels.push(`Other (${otherCount})`)
    }

    data.datasets[0].data.forEach((_count, i) => {
      data.datasets[0].backgroundColor.push(ColorMap(i).rgba().toString())
    })

    return data
  }

  get config() {
    return {
      data: this.graphData,
        type: 'pie',
        options: {
          responsive: true,
          aspectRatio: 2,
          layout: {
            padding: {
              bottom: 5
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              display: parseInt(getComputedStyle(document.body).width) > 500
            }
          }
        }
    }
  }
}
