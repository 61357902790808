import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.replyText = this.submitTarget.value
    this.lockCount = 0
    this.element.addEventListener('formLock', this.locked.bind(this))
    this.element.addEventListener('formUnLock', this.unlocked.bind(this))

    this.element.addEventListener('trix-file-accept', this.incrementLockCount.bind(this))
    this.element.addEventListener('trix-attachment-added', this.decrementLockCount.bind(this))
    this.element.addEventListener('trix-attachment-remove', this.decrementLockCount.bind(this))
  }

  locked(ev) {
    if (ev.detail == null || ev.detail.binary != true) {
      this.incrementLockCount(ev)
    } else {
      this.lock()
    }
  }

  unlocked(ev) {
    if (ev.detail == null || ev.detail.binary != true) {
      this.decrementLockCount()
    } else {
      this.unlock()
    }
  }

  incrementLockCount() {
    if (this.lockCount == 0) {
      this.lock()
    }

    this.lockCount++
  }

  decrementLockCount() {
    this.lockCount--

    if (this.lockCount == 0) {
      this.unlock()
    }
  }

  lock() {
    if (this.disabledText) {
      this.submitTarget.setAttribute('value', this.disabledText)
    }

    this.submitTarget.setAttribute('disabled', true)
  }

  unlock() {
    this.submitTarget.setAttribute('value', this.replyText)
    this.submitTarget.removeAttribute('disabled')
  }

  get disabledText() {
    return this.submitTarget.dataset.lockedFormText || 'Please wait...'
  }
}
