import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']

  toggle(ev) {
    ev.preventDefault()
    this.contentTarget.classList.toggle('d-none')
    ev.currentTarget.classList.toggle('toggled')
  }
}
