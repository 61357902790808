import { Controller } from 'stimulus'

import { Map, TileLayer, Marker } from 'leaflet'

export default class extends Controller {
  static targets = ['latitudeInput', 'longitudeInput']

  connect() {
    this.setupMap()
  }

  setupMap() {
    this.map = new Map(this.element, {
      center: this.center, zoom: 15, zoomControl: this.data.has('enableZoom'),
      zoomAnimation: true, fadeAnimation: true
    })

    new TileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/satellite-streets-v11',
        accessToken: this.data.get('mapboxToken')
      }
    ).addTo(this.map)

    this.marker = new Marker(this.markerCoords, {draggable: true})
    this.marker.on('dragend', (ev) => this.updateCoords(ev))
    this.marker.addTo(this.map)
  }

  updateCoords(ev) {
    const coords = ev.target.getLatLng()
    this.latitudeInputTarget.value = coords.lat
    this.longitudeInputTarget.value = coords.lng
  }

  get center() {
    return [
      parseFloat(this.data.get('latitude')), parseFloat(this.data.get('longitude'))
    ]
  }

  get markerCoords() {
    return [
      parseFloat(this.data.get('markerLatitude')), parseFloat(this.data.get('markerLongitude'))
    ]
  }
}
