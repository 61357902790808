import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.fire)
  }

  fire(ev) {
    // Disable on the next paint so that we don't interupt intended functionality
    window.requestAnimationFrame(() => {
      if (ev.target.form == null || ev.target.form.checkValidity()) {
        ev.target.setAttribute('disabled', true)

        if (ev.target.dataset.disabledWith) {
          ev.target.innerText = ev.target.dataset.disabledWith
        }
      }
    })
  }
}
