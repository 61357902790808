import { Controller } from 'stimulus'
import { useTargetMutation } from 'stimulus-use'

export default class extends Controller {
  static targets = ['view']
  static values = {
    navigate: Boolean
  }

  connect() {
    this.loaded = false

    if (this.element.getAttribute('data-controller').match('auto-load')) {
      useTargetMutation(this, { targets: ['view'] })
    } else {
      this.viewTargetAdded()
    }
  }

  viewTargetAdded() {
    if (this.loaded) { return }

    this.loaded = true
    if (window.location.hash.length > 0) {
      this.viewIndex = -1
      this.navigate(window.location.toString())
    } else {
      this.viewIndex = 0
      this.showView(this.viewTargets[0], 'Left')
    }
  }

  back() {
    if (this.navigateValue) {
      this.navigate(window.location.toString())
    }
  }

  jump(ev) {
    ev.preventDefault()
    this.navigate(ev.target.href)
  }

  navigate(href) {
    const newViewIndex = this.viewIndexForId(href)

    if (newViewIndex != null && this.viewIndex != newViewIndex) {
      if (this.viewIndex > -1) {
        const hideDir = newViewIndex > this.viewIndex ? 'Left' : 'Right'

        this.hideView(this.viewTargets[this.viewIndex], hideDir)
      }

      const showDir = newViewIndex > this.viewIndex ? 'Right' : 'Left'
      this.viewIndex = newViewIndex
      this.showView(this.viewTargets[this.viewIndex], showDir)
    } else if (newViewIndex == -1) {
      this.showView(this.viewTargets[0], 'Right')
    }
  }

  previous(ev) {
    ev.preventDefault()

    this.hideView(this.viewTargets[this.viewIndex], 'Right')

    if (this.viewIndex == 0) {
      this.viewIndex = this.viewTargets.length - 1
    } else {
      this.viewIndex -= 1
    }
    this.showView(this.viewTargets[this.viewIndex], 'Left')
  }

  next(ev) {
    ev.preventDefault()

    this.hideView(this.viewTargets[this.viewIndex], 'Left')

    if (this.viewIndex == this.viewTargets.length - 1) {
      this.viewIndex = 0
    } else {
      this.viewIndex += 1
    }

    this.showView(this.viewTargets[this.viewIndex], 'Right')
  }

  hideView(view, dir) {
    if (!view) {
      return
    }

    view.classList.remove(this.data.get('enterClass') + dir)
    view.classList.add('animated')
    view.classList.add(this.data.get('leaveClass') + dir)
    view.setAttribute('hidden', true)
  }

  showView(view, dir) {
    if (!view) {
      return
    }

    this.resetClasses(view)

    view.classList.remove(this.data.get('leaveClass') + dir)
    view.classList.add('animated')
    view.classList.add(this.data.get('enterClass') + dir)

    if (this.navigateValue && view.dataset.title) {
      document.title = view.dataset.title

      if (window._hsq) {
        window._hsq.push(['setPath', window.location.pathname])
        window._hsq.push(['trackPageView'])
      }
    }

    view.removeAttribute('hidden')

    if (view.id.length > 0) {
      this.updateUrl(view.id)
    }
  }

  viewIndexForId(href) {
    const hash = new URL(href).hash
    const id = hash.slice(1, hash.length) // Remove the leading #

    return this.viewTargets.findIndex((view) => view.id == id) || 0
  }

  resetClasses(view) {
    view.classList.remove(this.data.get('leaveClass') + 'Left')
    view.classList.remove(this.data.get('leaveClass') + 'Right')
    view.classList.remove(this.data.get('enterClass') + 'Left')
    view.classList.remove(this.data.get('enterClass') + 'Right')
  }

  updateUrl(hash) {
    if (this.navigateValue) {
      const url = new URL(window.location)
      const newHash = `#${hash}`

      if (url.hash != newHash) {
        url.hash = newHash
        window.history.pushState({}, '', url)
      }
    }
  }
}
