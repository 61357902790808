import { Controller } from 'stimulus'

// Bubble event which triggers auto load of content
export default class extends Controller {
  static values = { url: String }

  connect() {
    this.executed = false
  }

  exec() {
    const event = new CustomEvent(`auto-load:${this.urlValue}`, {
      bubbles: true
    })

    this.element.dispatchEvent(event)
  }
}
