import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'target']

  connect() { this.changed() }

  changed() {
    const value = this.sourceTarget.value
    const tmpl = this.element.querySelector(`#states-${value}`)

    this.targetTarget.innerHTML = tmpl.innerHTML
  }
}
