import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

// Automatically AJAX load and render the resource in the element
// Works with ManualAutoLoadController to show content quickly
export default class extends Controller {
  static targets = ['content']
  static values = {
    immediate: Boolean
  }

  connect() {
    this.loaded = false
    useIntersection(this)
    window.document.addEventListener(
      `auto-load:${this.data.get('url')}`, this.manualLoad.bind(this)
    )

    if (this.immediateValue) {
      this.appear()
    }
  }

  disconnect() {
    window.document.removeEventListener(`auto-load:${this.data.get('url')}`, this.manualLoad)
  }

  manualLoad() {
    this.appear()
  }

  async appear() {
    if (this.loaded == false) {
      const response = await fetch(this.data.get('url'))

      if (response.ok) {
        this.loaded = true
        const html = await response.text()
        this.contentTarget.innerHTML = html
      }
    }
  }
}
