import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['field'];

  handleClick(event) {
    event.preventDefault()
    this._fillForm()
  }

  handleEnterKey(event) {
    if (event.key !== 'Enter') {
      return
    }

    event.preventDefault()
    this._fillForm()
  }

  _fillForm() {
    this.fieldTargets.forEach((field) => {
      const formElement = document.querySelector(field.dataset.formElementId)

      if (field.dataset.value !== undefined) {
        formElement.value = field.dataset.value
      }
    })
  }
}

