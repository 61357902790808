import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['value']

  submit(ev) {
    ev.preventDefault()

    const asLink = this.selectedOption.dataset.asLink
    const value = this.valueTarget.value
    const detail = {}

    window.jQuery(this.element).modal('hide')

    if (asLink) {
      detail.type = 'html'
      detail.content = `<a href="${value}">${asLink}</a>`
    } else {
      detail.type = 'string'
      detail.content = value
    }

    const insertEvent = new CustomEvent('trix-template:insert', { detail: detail, bubbles: true })
    this.element.insertFocus.dispatchEvent(insertEvent)
  }

  get selectedOption() {
    return Array.from(this.valueTarget).find(option => option.selected)
  }
}
