import { Marker } from 'leaflet'
import MarkeredMapController from './markered_map_controller'

export default class extends MarkeredMapController {
  addMarker(position, title, data) {
    const marker = new Marker(position, {
      title: title,
      draggable: true
    })

    marker.propertyId = data.id

    marker.on('moveend', this.markerMoveEnd.bind(this))
    marker.on('click', this.markerClick.bind(this))
    marker.addTo(this.map)
  }

  markerMoveEnd(ev) {
    const overrideEl  = document.querySelector(`input[name="properties[${ev.target.propertyId}][override_geocoding]"]`)
    const latitudeEl  = document.querySelector(`input[name="properties[${ev.target.propertyId}][latitude]"]`)
    const longitudeEl = document.querySelector(`input[name="properties[${ev.target.propertyId}][longitude]"]`)

    overrideEl.value  = true
    latitudeEl.value  = ev.target.getLatLng().lat
    longitudeEl.value = ev.target.getLatLng().lng
  }

  markerClick(ev) {
    document.querySelectorAll(`.highlightable[data-property-id="${ev.target.propertyId}"]`).forEach((el) => {
      ScrollTo(el)

      el.addEventListener('animationend', (_ev) => {
        el.classList.remove('headShake')
      })

      el.classList.add('headShake')
    })
  }
}
