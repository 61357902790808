import { Controller } from 'stimulus'

import { Map, TileLayer, Marker } from 'leaflet'

export default class extends Controller {
  static values = {
    mapboxToken: String,
    latitude: Number,
    longitude: Number,
    enableZoom: Boolean,
    zoom: Number
  }

  connect() {
    this.setupMap()
  }

  setupMap() {
    this.map = new Map(this.element, {
      center: this.center, zoom: this.zoomValue, zoomControl: this.enableZoomValue,
      zoomAnimation: true, fadeAnimation: true
    })

    new TileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/streets-v11',
        accessToken: this.mapboxTokenValue
      }
    ).addTo(this.map)

    this.marker = new Marker(this.center, {draggable: false})
    this.marker.addTo(this.map)
  }

  get center() {
    return [
      this.latitudeValue, this.longitudeValue
    ]
  }
}
