import { Controller } from 'stimulus'
import Upload from '../../lib/upload'

export default class extends Controller {
  connect() {
    this.imageable = this.data.has('image-quality')
    this.element.addEventListener('trix-attachment-add', this.fileAttached.bind(this))
  }

  fileAttached(event) {
    const attachment = event.attachment
    const file       = attachment.file

    if (file == undefined) { return }

    const uploader = new Upload(
      this.data.get('csrfToken'), {},
      {
        filename:  file.name,
        size:      file.size,
        mime_type: file.type
      }
    )

    uploader.on('upload:progress', function(event) {
      const progress = event.loaded / event.total * 100
      attachment.setUploadProgress(progress)
    })

    uploader.create(this.data.get('presignUrl'), file)
            .then((data) => {
              attachment.setAttributes({
                url: data[this.data.get('imgAttr') || 'imgUrl'],
                href: data.auth_url,
                resourceUrl: data.resourceUrl
              })

              this.broadcastAdded()
            }).catch((error) => {
              attachment.remove()
              console.error(error)
              alert(`There was a problem uploading the file ${file.name}.\n\n${error.message}.`)
            })
  }

  broadcastAdded() {
    const event = new CustomEvent('trix-attachment-added', { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
