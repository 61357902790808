import { Controller } from 'stimulus'
import { uniq } from 'lodash'

export default class extends Controller {
  static targets = ['toggler', 'details']

  connect() {
    this.adjustToggleState()

    this.detailsTargets.forEach((detail) => {
      detail.addEventListener('toggle', this.adjustToggleState.bind(this))
    })
  }

  all(ev) {
    ev.preventDefault()
    this.collapsed = !this.collapsed

    if (this.collapsed) {
      this.detailsTargets.forEach((detail) => detail.removeAttribute('open'))
    } else {
      this.detailsTargets.forEach((detail) => detail.setAttribute('open', true))
    }

    this.adjustToggleState()
  }

  adjustToggleState() {
    if (this.detailOpenStates.length == 1) {
      if (this.detailOpenStates[0] == true) {
        this.togglerTarget.innerText = '[+] Expand All'
        this.collapsed = true
      } else {
        this.togglerTarget.innerText = '[-] Collapse All'
        this.collapsed = false
      }
    }
  }

  get detailOpenStates() {
    return uniq(this.detailsTargets.map((detail) => detail.getAttribute('open') === null))
  }
}
