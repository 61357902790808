import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    const blockquotes = this.element.querySelectorAll('blockquote')

    for (var i = blockquotes.length - 1; i >= 0; i--) {
      const blockquote = blockquotes[i]
      blockquote.classList.add('collapse-processed')

      const detailsHtml = `<details><summary class="text-muted">...</summary>${blockquote.outerHTML}</details>`
      blockquote.insertAdjacentHTML('afterend', detailsHtml)
      blockquote.remove()
    }
  }
}
