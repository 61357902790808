import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['progress', 'idInput', 'deleteButton', 'link']

  connect() {
    this.eventMap = [
      [`upload:start:${this.data.get('id')}`, this.uploadStart.bind(this)],
      [`upload:progress:${this.data.get('id')}`, this.uploadProgress.bind(this)],
      [`upload:failure:${this.data.get('id')}`, this.uploadFailure.bind(this)],
      [`upload:success:${this.data.get('id')}`, this.uploadSuccess.bind(this)]
    ]

    this.eventMap.forEach((keyAndCallback) => {
      document.addEventListener(keyAndCallback[0], keyAndCallback[1])
    })
  }

  disconnect() {
    this.eventMap.forEach((keyAndCallback) => {
      document.removeEventListener(keyAndCallback[0], keyAndCallback[1])
    })
  }

  remove(ev) {
    ev.preventDefault()
    this.element.parentElement.removeChild(this.element)
  }

  uploadStart(event) {
    this.progressTarget.setAttribute('value', 0)
    this.element.classList.add('uploading')
    this.element.classList.remove('failure')
    this.deleteButtonTarget.setAttribute('href', event.detail.data.resourceUrl)
    this.idInputTarget.value = event.detail.data.id
  }

  uploadProgress(event) {
    const progress = (event.detail.data.loaded / event.detail.data.total) * 100
    this.progressTarget.setAttribute('value', Math.min(100, progress))
  }

  uploadFailure() {
    this.element.classList.remove('uploading')
    this.element.classList.add('failure')
  }

  uploadSuccess(event) {
    this.element.classList.remove('uploading')
    this.idInputTarget.value = event.detail.data.id
    this.progressTarget.setAttribute('value', 100)

    if (this.hasLinkTarget) {
      this.linkTarget.href= event.detail.data.auth_url
    }
  }
}
