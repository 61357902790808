import { Controller } from 'stimulus'
import { v4 as uuid } from 'uuid'
import CanvasOverlay from '../../lib/canvas_overlay'
import Upload from '../../lib/upload'
import ResizeImage from '../../lib/resize_image'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.imageable = this.data.has('image-quality')
    this.timestamp = this.imageable && this.data.get('timestamp') === 'true'

    this.inputTarget.addEventListener('change', (event) => {
      this.uploadFiles(event)
    })
  }

  async uploadFiles(event) {
    const files = []

    this.dispatchUploadEvent('formLock', {})

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]

      if (this.imageable) {
        try {
          const processedImage = await this.processImage(file)
          processedImage.name = file.name

          files.push(processedImage)
        } catch(error) {
          console.error('Unable to process image, continue with original')
          console.error(error)
          files.push(file)
        }
      } else {
        files.push(file)
      }
    }

    this.inputTarget.value = null // Reset input value for next selection

    try {
      await Promise.all(
        files.map((file) => {
          const details = {
            file:          file,
            filename:      file.name,
            tempId:        uuid()
          }

          return this.uploadFile(file, details)
        })
      )
    } catch(error) {
      console.error(error)
      alert('There was a problem processing the file. Error #1199. Try again.')
    }

    this.dispatchUploadEvent('formUnLock', {})
  }

  addOverlay(blob) {
    if (this.timestamp) {
      return CanvasOverlay(blob, new Date().toLocaleString())
    } else {
      return Promise.resolve(blob)
    }
  }

  processImage(file) {
    const imageQuality = parseFloat(this.data.get('image-quality') || '1.0')

    return ResizeImage(file, imageQuality).then((blob) => {
      return this.addOverlay(blob)
    }).catch((error) => {
      // Resizing failed, let's just try to add the timestamp, if applicable
      console.warn(`Resizing image failed, trying to add timestamp: ${error}`)
      return this.addOverlay(file)
    })
  }

  uploadFile(blob, details) {
    return new Promise((resolve, reject) => {
      this.dispatchUploadEvent('upload:init', details)

      const uploader = new Upload(
        this.data.get('csrfToken'), {},
        {
          filename:  details.filename,
          size:      blob.size,
          mime_type: blob.type
        }
      )

      const photo = {
        tempId:    details.tempId,
        filename:  details.filename,
        blob:      blob
      }

      document.addEventListener(
        `${this.data.get('resolveEvent')}:${photo.tempId}`,
        resolve,
        { once: true }
      )

      uploader.on('upload:progress', (progressData) => {
        this.dispatchUploadEvent(`upload:progress:${photo.tempId}`, {
          data: progressData,
          file: photo.blob
        })
      }).on('upload:failure', (uploadData) => {
        this.dispatchUploadEvent(`upload:failure:${photo.tempId}`, {
          data: uploadData
        })
        throw(uploadData)
      }).on('complete:success', async (uploadData) => {
        this.dispatchUploadEvent(`upload:success:${photo.tempId}`, {
          data: uploadData,
          file: photo.blob
        })

        // Clean up any straggler UIs
        setTimeout(() => {
          this.dispatchUploadEvent(`upload:success:${photo.tempId}`, {
            data: uploadData,
            file: photo.blob
          })
        }, 5000)
      })

      uploader.create(this.data.get('presignUrl'), photo.blob).then(resolve).catch(reject)
    })
  }

  dispatchUploadEvent(key, detail) {
    const event = new CustomEvent(key, { bubbles: true, detail: detail })
    this.element.dispatchEvent(event)
  }
}
