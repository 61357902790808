import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'

export default class GraphController extends Controller {
  static values = { label: String }

  connect() {
    this.resize()
    this._json = []

    // Prevent double render by when going back
    if (this.isEmpty) {
      setTimeout(() => {
        new Chart(this.element, this.config)
      }, 250)
    }
  }

  resize() {
    const flooredWidth = Math.floor(this.element.width)
    this.element.style.width = `${flooredWidth}px`
    this.element.style.height = `${Math.floor(flooredWidth / (16/9))}px`
  }

  json() {
    if (this._json.length == 0 && this.data.get('data')) {
      this._json = JSON.parse(this.data.get('data'))
    }

    return this._json
  }

  get isEmpty() {
    if (this.element.width == 0) { return true }

    const context = this.element.getContext('2d')

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, this.element.width, this.element.height).data.buffer
    )

    return !pixelBuffer.some(color => color !== 0)
  }

  get chartLabel() {
    if (this.hasLabelValue) {
      return this.labelValue
    } else {
      return ''
    }
  }
}
