import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.queueStatusCheck()
  }

  queueStatusCheck() {
    setTimeout(() => {
      if (this.data.get('path')) {
        this.checkStatus()
      } else {
        this.queueStatusCheck()
      }
    }, 250)
  }

  async checkStatus() {
    try {
      const response = await fetch(this.data.get('path'), {
        headers: {
          'Accept': 'application/json'
        }
      })

      if (response.status == 200) {
        this.queueStatusCheck()
      } else if (response.status == 418) {
        this.error(response)
      } else if (response.status == 303) {
        this.redirect(response)
      } else if (response.status == 429) {
        this.rateLimited(response)
      } else {
        this.unhandled(response)
      }
    } catch(error) {
      console.log('Download polled task failed')
      console.error(error)

      this.queueStatusCheck()
    }
  }

  async redirect(response) {
    try {
      const json = await response.json()

      this.updateRedirectResultLinks(json.result)
      this.removeModal()

      if (json.completion_message) {
        window.toastr.success(json.completion_message)
      }

      window.location = json.result
    } catch(error) {
      console.log('Download polled task failed')
      console.error(error)

      this.queueStatusCheck()
    }
  }

  async error(response) {
    try {
      const json = await response.json()

      this.removeModal()

      console.error(`Encountered and error: ${response.status}: ${json.error}`)
      if (json.failure_path != null) {
        window.location.replace(json.failure_path)
      } else {
        window.toastr.error(json.error || 'We encountered a problem. Contact support')
      }
    } catch(error) {
      console.log('Download polled task failed')
      console.error(error)
    }
  }

  async rateLimited() {
    this.removeModal()
    window.toastr.error('Limit reached. Try again later')
  }

  async unhandled(response) {
    try {
      const txt = await response.text()
      console.error(`Unhandled response: ${response.status}: ${txt}`)
    } catch(error) {
      console.log('Download polled task failed')
      console.error(error)
    }
  }

  updateRedirectResultLinks(url) {
    [].forEach.call(document.querySelectorAll('.redirect-result'), (el) => {
      el.href = url
    })
  }

  removeModal() {
    this.element.remove()
  }
}
