import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'variable', 'fallback', 'example']

  connect() {
    this.changedVariable()
  }

  submit(ev) {
    ev.preventDefault()

    window.jQuery(this.element).modal('hide')

    const selectedOption    = this.selectedOption()
    const variable          = this.variableTarget.value
    const fallback          = this.fallbackTarget.value
    const pretty            = selectedOption.text
    const fallbackTitleText = fallback.length > 0 ? `Fallback: ${fallback}` : ''
    const fallbackTmplText  = fallback.length > 0 ? ` | default: '${fallback}'` : ''

    const detail = {
      type: 'attachment',
      content: {
        content: `<span class="template-placeholder" data-controller="trix-template-placeholder">
                  <span class="editor-element" title="${fallbackTitleText}">${pretty}</span>
                  <span class="render-element">{{ ${variable}${fallbackTmplText} }}</span>
                </span>`,
        contentType: 'templatePlaceholder'
      }
    }

    const insertEvent = new CustomEvent('trix-template:insert', { detail: detail, bubbles: true })
    this.element.insertFocus.dispatchEvent(insertEvent)
  }

  changedVariable() {
    const selectedOption = this.selectedOption()
    const fallback       = selectedOption.getAttribute('data-fallback')
    const example        = selectedOption.getAttribute('data-example')

    this.exampleTarget.innerText = ''

    if (example != null) {
      this.exampleTarget.innerHTML = `ex: ${example}`
    }

    this.fallbackTarget.removeAttribute('required')
    this.fallbackTarget.removeAttribute('placeholder')
    this.fallbackTarget.setAttribute('disabled', true)
    this.fallbackTarget.value = ''

    if (fallback == '' || fallback == 'false' || fallback != null) {
      if (this.fallback != 'true') {
        this.fallbackTarget.setAttribute('placeholder', fallback)
      }

      this.fallbackTarget.removeAttribute('disabled')
      this.fallbackTarget.setAttribute('required', true)
    }
  }

  selectedOption() {
    return Array.from(this.variableTarget).find(option => option.selected)
  }
}
