import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'source', 'street1', 'city', 'country', 'state', 'postalCode',
    'alreadyGeocoded', 'latitude', 'longitude', 'formattedAddress',
    'streetNumber', 'route'
  ]

  connect() {
    if (google == undefined) {
      alert('Unable to load Google Maps Library')
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      this.sourceTarget, { types: ['address'] }
    )

    this.element.addEventListener('keydown', (ev) => {
      if (ev.keyCode === 13) {
        // Prevent premature form submission
        ev.preventDefault()
      }
    })

    this.sourceTarget.addEventListener('change', () => {
      // Reset any previous searches
      const place = {
        street1:    '',
        city:       '',
        state:      '',
        postalCode: '',
        country:    '',
        alreadyGeocoded: false,
        latitude: '',
        longitude: '',
        formattedAddress: '',
        streetNumber: '',
        route: ''
      }

      this.update(place)
    })

    this.autocomplete.addListener('place_changed', () => {
      const place = this.place

      this.update(place)
    })
  }

  update(place) {
    Object.keys(place).forEach((key) => {
      if (this[`has${key.charAt(0).toUpperCase() + key.slice(1)}Target`]) {
        this[`${key}Target`].value = place[key]
      }
    })
  }

  get place() {
    const place = this.autocomplete.getPlace()

    if (place.address_components == undefined) {
      return {}
    } else {
      const city = place.address_components.find((component) => {
        return component.types.indexOf('locality') > -1
      })

      const state = place.address_components.find((component) => {
        return component.types.indexOf('administrative_area_level_1') > -1
      })

      const postalCode = place.address_components.find((component) => {
        return component.types.indexOf('postal_code') > -1
      })

      const country = place.address_components.find((component) => {
        return component.types.indexOf('country') > -1
      })

      const street_number = place.address_components.find((component) => {
        return component.types.indexOf('street_number') > -1
      })

      const route = place.address_components.find((component) => {
        return component.types.indexOf('route') > -1
      })

      return {
        street1:    place.name,
        city:       city?.long_name,
        state:      state?.short_name,
        postalCode: postalCode?.long_name,
        country:    country?.short_name,
        alreadyGeocoded: (place.geometry?.location?.lat() !== undefined),
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
        formattedAddress: place.formatted_address,
        streetNumber: street_number?.long_name,
        route: route?.long_name
      }
    }
  }
}
