import { Controller } from 'stimulus'
import { template } from 'lodash'

export default class extends Controller {
  static targets = ['gallery', 'template']
  static defaultImg = 'data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwYXBlcmNsaXAiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1wYXBlcmNsaXAgZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00My4yNDYgNDY2LjE0MmMtNTguNDMtNjAuMjg5LTU3LjM0MS0xNTcuNTExIDEuMzg2LTIxNy41ODFMMjU0LjM5MiAzNGM0NC4zMTYtNDUuMzMyIDExNi4zNTEtNDUuMzM2IDE2MC42NzEgMCA0My44OSA0NC44OTQgNDMuOTQzIDExNy4zMjkgMCAxNjIuMjc2TDIzMi4yMTQgMzgzLjEyOGMtMjkuODU1IDMwLjUzNy03OC42MzMgMzAuMTExLTEwNy45ODItLjk5OC0yOC4yNzUtMjkuOTctMjcuMzY4LTc3LjQ3MyAxLjQ1Mi0xMDYuOTUzbDE0My43NDMtMTQ2LjgzNWM2LjE4Mi02LjMxNCAxNi4zMTItNi40MjIgMjIuNjI2LS4yNDFsMjIuODYxIDIyLjM3OWM2LjMxNSA2LjE4MiA2LjQyMiAxNi4zMTIuMjQxIDIyLjYyNkwxNzEuNDI3IDMxOS45MjdjLTQuOTMyIDUuMDQ1LTUuMjM2IDEzLjQyOC0uNjQ4IDE4LjI5MiA0LjM3MiA0LjYzNCAxMS4yNDUgNC43MTEgMTUuNjg4LjE2NWwxODIuODQ5LTE4Ni44NTFjMTkuNjEzLTIwLjA2MiAxOS42MTMtNTIuNzI1LS4wMTEtNzIuNzk4LTE5LjE4OS0xOS42MjctNDkuOTU3LTE5LjYzNy02OS4xNTQgMEw5MC4zOSAyOTMuMjk1Yy0zNC43NjMgMzUuNTYtMzUuMjk5IDkzLjEyLTEuMTkxIDEyOC4zMTMgMzQuMDEgMzUuMDkzIDg4Ljk4NSAzNS4xMzcgMTIzLjA1OC4yODZsMTcyLjA2LTE3NS45OTljNi4xNzctNi4zMTkgMTYuMzA3LTYuNDMzIDIyLjYyNi0uMjU2bDIyLjg3NyAyMi4zNjRjNi4zMTkgNi4xNzcgNi40MzQgMTYuMzA3LjI1NiAyMi42MjZsLTE3Mi4wNiAxNzUuOTk4Yy01OS41NzYgNjAuOTM4LTE1NS45NDMgNjAuMjE2LTIxNC43Ny0uNDg1eiI+PC9wYXRoPjwvc3ZnPg=='

  connect() {
    this.tmpl = template(this.templateTarget.innerHTML)
    this.element.addEventListener('upload:init', this.uploadInit.bind(this))
  }

  async uploadInit(event) {
    let fileAsDataUrl

    try {
      fileAsDataUrl = await this._readFileAsDataUrl(event.detail.file)
    } catch(error) {
      fileAsDataUrl = ''
    }

    this.galleryTarget.insertAdjacentHTML(
      this.insertPosition,
      this.tmpl({
        event: event, inputName: this.data.get('upload-input-name'), imageUrl: (fileAsDataUrl || this.constructor.defaultImg)
      })
    )
  }

  _readFileAsDataUrl(file) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader()

      reader.addEventListener('load', (readerEv) => {
        resolve(readerEv.target.result)
      })

      reader.readAsDataURL(file)
    })
  }

  get insertPosition() {
    return this.element.dataset.insertPosition || 'beforeend'
  }
}
