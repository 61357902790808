class RGBa {
  constructor(r, g, b, a) {
    this.r = r
    this.g = g
    this.b = b
    this.a = a
  }

  toString() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
  }
}

export default class {
  constructor(r, g, b) {
    this.r = r
    this.g = g
    this.b = b
  }

  rgba(a) {
    a = a || 1
    return new RGBa(this.r, this.g, this.b, a)
  }
}
