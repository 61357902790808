import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['options']

  connect() {
    this.setMaxHeight()
    this.element.classList.add('animated')
  }

  updateOptions(ev) {
    if (['dropdown', 'checkbox', 'radio'].indexOf(ev.target.value) > -1) {
      this.optionsTarget.removeAttribute('hidden')
      this.optionsTarget.querySelector('label').innerText = `${ev.target.nextElementSibling.innerText} Options`
    } else {
      this.optionsTarget.setAttribute('hidden', true)
    }

    this.setMaxHeight()
  }

  setMaxHeight() {
    this.element.style.maxHeight = null
    window.requestAnimationFrame(() => {
      this.element.style.maxHeight = `${this.element.offsetHeight}px`
    })
  }

  remove() {
    this.element.classList.add('bounceOutRight')
    this.element.classList.add('height-zero')
  }
}
