import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['disabledInputs']

  connect() {
    this.changeListener = () => { this._validate() }
    this.element.addEventListener('change', this.changeListener)
    this.changeListener()
  }

  _validate() {
    if (this._valid()) {
      this.disabledInputsTargets.forEach((el) => {
        el.removeAttribute('disabled')
      })
    } else {
      this.disabledInputsTargets.forEach((el) => {
        el.setAttribute('disabled', true)
      })
    }
  }

  _valid() {
    const form = this.element

    const requiredInputNames = [].map.call(form.querySelectorAll('[required]'), (el) => {
      return el.getAttribute('name')
    }).filter((v, i, a) => a.indexOf(v) === i)

    const requiredValidity = requiredInputNames.map((inputName) => {
      const inputsByName = form.querySelectorAll(`[name="${inputName}"]`)
      const validityForRequiredInputs = [].map.call(inputsByName, (el) => {
        return el.validity.valueMissing
      })

      return validityForRequiredInputs.indexOf(false) > -1
    })

    return requiredValidity.indexOf(true) > -1
  }
}
