import { Controller } from 'stimulus'
import Litepicker from 'litepicker'

export default class extends Controller {
  static targets = ['start', 'end']

  connect() {
    this.picker = new Litepicker({
      element:         this.startTarget,
      elementEnd:      this.endTargetElement,
      numberOfMonths:  this.numberOfMonths,
      numberOfColumns: this.numberOfColumns,
      startDate:       this.startDate,
      endDate:         this.endDate,
      singleMode:      this.singleMode,
      firstDay:        0,
      format:          'MM/DD/YYYY',
      minDate:         this.minDate
    })

    this.startTarget.setAttribute('readonly', true)

    if (this.hasEndTarget) {
      this.endTarget.setAttribute('readonly', true)
    }

    this.picker.on('selected', (ev) => {
      const event = new CustomEvent('datepicker:selected', {
        detail: { event: ev }, bubbles: true
      })

      this.startTarget.dispatchEvent(event)
    })

    this.startTarget.setAttribute('pattern', '[0-9]{2}/[0-9]{2}/[0-9]{4}')
    this.startTarget.setAttribute('title', 'Date in MM/DD/YYYY format')
    this.startTarget.setAttribute('placeholder', 'MM/DD/YYYY')

    if (this.hasEndTarget) {
      this.endTarget.setAttribute('pattern', '[0-9]{2}/[0-9]{2}/[0-9]{4}')
      this.endTarget.setAttribute('title', 'Date in MM/DD/YYYY format')
      this.endTarget.setAttribute('placeholder', 'MM/DD/YYYY')
    }
  }

  show() {
    this.picker.show()
  }

  get numberOfMonths() {
    return parseInt(
      this.data.get('numberOfMonths') || '1'
    )
  }

  get numberOfColumns() {
    return parseInt(
      this.data.get('numberOfColumns') || '1'
    )
  }

  get startDate() {
    return this.startTarget.value
  }

  get endDate() {
    if (this.hasEndTarget) {
      return this.endTarget.value
    } else {
      return null
    }
  }

  get singleMode() {
    return !this.hasEndTarget
  }

  get endTargetElement() {
    if (this.hasEndTarget) {
      return this.endTarget
    } else {
      return null
    }
  }

  get minDate() {
    return this.data.get('minDate')
  }
}
