import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    src: String,
    buttonTitle: String,
    placeholderTitle: String,
    imgOnly: Boolean
  }

  connect() {
    if (this.element.initialized) {
      this.setup()
    } else {
       this.element.addEventListener('trix-initialize', () => this.setup())
    }
  }

  setup() {
    const toolbar = document.getElementById(this.element.getAttribute('toolbar'))

    const buttonGroup = toolbar.querySelector('.trix-button-group--text-tools')
    buttonGroup.insertAdjacentHTML('beforeend',
      `<button type="button" class="trix-button trix-button--icon trix-button--icon-qr-code" title="${this.buttonTitle}" tabindex="-1">${this.buttonTitle}</button>`
    )

    const button = buttonGroup.querySelector('.trix-button--icon-qr-code')

    button.addEventListener('click', this.click.bind(this))
  }

  click(ev) {
    ev.preventDefault()

    const detail = {
      type: 'attachment',
      content: {
        content: this.content,
        contentType: 'templatePlaceholder'
      }
    }

    const insertEvent = new CustomEvent('trix-template:insert', { detail: detail, bubbles: true })
    this.element.dispatchEvent(insertEvent)
  }

  get content() {
    if (this.imgOnlyValue) {
      return `<img src="${this.srcValue}">`
    } else {
      return `<div class="template-placeholder qr-url" data-controller="trix-template-placeholder"><span class="editor-element">${this.placeholderTitle}<br/><i></i></span><span class="render-element"><img src="${this.src}"></span></div>`
    }
  }

  get src() {
    if (this.srcValue) {
      return this.srcValue
    } else {
      return '{{ property.formatted_id_and_address_key | portal_auth_url | qr_url }}'
    }
  }

  get placeholderTitle() {
    if (this.placeholderTitleValue) {
      return this.placeholderTitleValue
    } else {
      return 'Portal URL QR Code'
    }
  }

    get buttonTitle() {
    if (this.buttonTitleValue) {
      return this.buttonTitleValue
    } else {
      return `Insert ${this.placeholderTitle}`
    }
  }
}
