import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('trix-template:insert', (ev) => {
      if (ev.detail.type == 'attachment') {
        const attachment = new Trix.Attachment(ev.detail.content)

        this.element.editor.insertAttachment(attachment)
      } else if (ev.detail.type == 'html') {
        this.element.editor.insertHTML(ev.detail.content)
      } else if (ev.detail.type == 'string') {
        this.element.editor.insertString(ev.detail.content)
      }

      this.element.editor.insertString(' ')
      this.element.focus()
    })
  }
}
