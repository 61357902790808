import * as orientation from 'exif-orientation'
const canvas = document.createElement('canvas')
let orient = true

// black 2x1 JPEG, with the following meta information set:
// - EXIF Orientation: 6 (Rotated 90° CCW)
const testImageURL =
  'data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA' +
  'AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA' +
  'QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE' +
  'BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x' +
  'ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA' +
  'AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q=='
const img = document.createElement('img')
img.onload = function () {
  // Check if browser supports automatic image orientation:
  orient = img.width === 2 && img.height === 1
}
img.src = testImageURL

function blobToImg(blob) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()

    img.onload = () => resolve(img)
    img.onerror = (error) => reject(error)

    reader.readAsDataURL(blob)

    reader.onload = function() {
      img.src = reader.result
    }
  })
}

function orientationFromBlob(blob) {
  return new Promise((resolve, reject) => {
    orientation(blob, (error, orientation) => {
      if (error) {
        reject(error)
      } else {
        resolve(orientation)
      }
    })
  })
}

function canvasWithImg(img) {
  const ctx = canvas.getContext('2d', { alpha: false })

  canvas.width = img.width
  canvas.height = img.height

  return ctx
}

export default function(blob, quality) {
  return new Promise((resolve, reject) => {
    orientationFromBlob(blob).then((orientation) => {
      blobToImg(blob).then((img) => {
        const ctx = canvasWithImg(img)

        if (orient) {
          if (4 < orientation.exif && orientation.exif < 9) {
            canvas.width = img.height
            canvas.height = img.width
          } else {
            canvas.width = img.width
            canvas.height = img.height
          }

          // transform context before drawing image
          switch (orientation.exif) {
            case 2: ctx.transform(-1, 0, 0, 1, img.width, 0); break
            case 3: ctx.transform(-1, 0, 0, -1, img.width, img.height); break
            case 4: ctx.transform(1, 0, 0, -1, 0, img.height); break
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break
            case 6: ctx.transform(0, 1, -1, 0, img.height, 0); break
            case 7: ctx.transform(0, -1, -1, 0, img.height, img.width); break
            case 8: ctx.transform(0, -1, 1, 0, 0, img.width); break
            default: break
          }
        }

        ctx.drawImage(img, 0, 0)

        canvas.toBlob(resolve, 'image/jpeg', quality)
      })
    }).then(() => {
      canvas.width = 1
      canvas.height = 1
      console.log('clear resize')
    }).catch(reject)
  })
}
