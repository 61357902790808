import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['progress', 'link', 'deleteButton']

  connect() {
    this.eventMap = [
      [`upload:start:${this.data.get('id')}`, this.uploadStart.bind(this)],
      [`upload:progress:${this.data.get('id')}`, this.uploadProgress.bind(this)],
      [`upload:failure:${this.data.get('id')}`, this.uploadFailure.bind(this)],
      [`upload:success:${this.data.get('id')}`, this.uploadSuccess.bind(this)]
    ]

    this.eventMap.forEach((keyAndCallback) => {
      document.addEventListener(keyAndCallback[0], keyAndCallback[1])
    })
  }

  disconnect() {
    this.eventMap.forEach((keyAndCallback) => {
      document.removeEventListener(keyAndCallback[0], keyAndCallback[1])
    })
  }

  async remove(ev) {
    ev.preventDefault()
    if (ev.currentTarget.href) {
      await fetch(ev.currentTarget.href, { method: 'DELETE'} )
      this.element.parentElement.removeChild(this.element)
    }
  }

  uploadStart(_event) {
    this.progressTarget.setAttribute('value', 0)
    this.element.classList.add('uploading')
    this.element.classList.remove('failure')
    this.element.parentElement.scrollTo(0)
    // this.deleteButtonTarget.setAttribute('href', event.detail.data.resourceUrl)
  }

  uploadProgress(event) {
    const progress = (event.detail.data.loaded / event.detail.data.total) * 100
    this.progressTarget.setAttribute('value', Math.min(100, progress))
  }

  uploadFailure() {
    this.element.classList.remove('uploading')
    this.element.classList.add('failure')
  }

  uploadSuccess(event) {
    this.element.classList.remove('uploading')
    this.progressTarget.setAttribute('value', 100)

    if (this.hasLinkTarget) {
      this.linkTargets.forEach((target) => target.href= event.detail.data.url)
    }
  }
}
