import { Controller } from 'stimulus'

// Link that inserts the modal to download
export default class extends Controller {
  download(ev) {
    ev.preventDefault()
    this.insertModal()

    const req = new XMLHttpRequest()

    req.addEventListener('load', (ev) => {
      this.modal.setAttribute('data-download-polled-task-path', ev.currentTarget.responseURL)
    })

    req.open('GET', this.element.href)
    req.send()
  }

  insertModal() {
    this.modal = this.tmpl
    document.body.insertAdjacentElement('beforeend', this.modal)
  }

  removeModal() {
    this.modal.parentElement.removeChild(this.modal)
  }

  get tmpl() {
    return document.querySelector('#download-polled-task-modal').content.cloneNode(true).children[0]
  }
}
