import GraphController from './graph_controller'
import ColorMap from '../../helpers/color_map'

export default class extends GraphController {
  get graphData() {
    const data = {
      datasets: [
        {
          fill: false,
          data: [],
          borderColor: ColorMap(0).rgba(0.7).toString(),
          backgroundColor: ColorMap(0).rgba(1).toString(),
          label: this.chartLabel
        }
      ],
      labels: []
    }

    this.json().reverse().forEach((entry) => {
      data.datasets[0].data.push(entry.count)
      data.labels.push(entry.label)
    })

    return data
  }

  get config() {
    return {
      data: this.graphData,
      type: 'line',
      options: {
        responsive: true,
        aspectRatio: 2,
        plugins: { legend: { display: false } }
      }
    }
  }
}
