import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('maps:markerClicked', (ev) => {
      const row = document.getElementById(ev.detail.marker.id)

      if (row) {
        const checkbox = row.querySelector('input[type="checkbox"]')

        checkbox.checked = !checkbox.checked
        checkbox.parentElement.parentElement.scrollIntoView(true, {behavior: 'smooth'})
        checkbox.parentElement.parentElement.classList.add('animated','flash')

        const event = new CustomEvent('change')

        checkbox.dispatchEvent(event) // Keep this semicolon
      }
    })
  }
}
