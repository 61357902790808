import { Controller } from 'stimulus'
import { template } from 'lodash'

export default class extends Controller {
  static targets = ['template', 'confirm']
  static values = { word: String }

  connect() {
    this.submitted = false

    if (this.hasTemplateTarget) {
      this.tmpl = template(this.templateTarget.innerHTML)
    }

    this.regex = new RegExp(this.wordValue, 'i')
  }

  insertTmpl(ev) {
    ev.preventDefault()
    ev.stopPropagation()

    this.element.insertAdjacentHTML(
      'afterbegin',
      this.tmpl({
        word: this.wordValue
      })
    )

    this.element.querySelector('[name="confirmation"]').focus()
  }

  preventEnter(ev) {
    if (ev.keyCode == 13) {
      ev.preventDefault()
      ev.stopPropagation()

      if (!this.submitted && ev.target.value.match(this.regex)) {
        this.submitted = true

        const clickEvent = new CustomEvent('click', { bubbles: true })
        this.confirmTarget.dispatchEvent(clickEvent)

        this.element.submit()
      }
    }
  }

  confirming(ev) {
    if (ev.target.value.match(this.regex)) {
      ev.target.classList.remove('is-invalid')
      this.confirmTarget.removeAttribute('disabled')
    } else {
      ev.target.classList.add('is-invalid')
      this.confirmTarget.setAttribute('disabled', true)
    }
  }

  confirm(ev) {
    ev.preventDefault()

    if (!this.submitted) {
      this.submitted = true
      this.element.submit()
    }
  }

  cancel(ev) {
    ev.preventDefault()
    this.element.firstElementChild.remove()
  }

  submit(ev) {
    if (!this.hasConfirmTarget) {
      this.insertTmpl(ev)
    }
  }
}
