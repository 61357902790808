import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['indicator']
  static values = {
    path: String,
    timeout: Number
  }

  connect() {
    this.documentListener = document.addEventListener(
      'users:update-notification-stats', this.updateUndeliveredWebNotificationsCount.bind(this)
    )

    useIntersection(this, this.options)
  }

  disconnect() {
    clearTimeout(this.timeout)
    document.removeEventListener(
      'users:update-notification-stats', this.documentListener
    )
  }

  appear() {
    this.timeout = setTimeout(
      this.updateUndeliveredWebNotificationsCount.bind(this),
      this.timeoutValue
    )
  }

  disappear() {
    clearTimeout(this.timeout)
  }

  async updateUndeliveredWebNotificationsCount() {
    clearTimeout(this.timeout)

    try {
      const response = await fetch(this.pathValue, {
        headers: {
          'Accept': 'application/json'
        }
      })

      // Signed out
      if (response.status == 401) {
        window.location.reload()
      }

      const json = await response.json()

      this.indicatorTargets.forEach((target) => {
        if (parseInt(json.undelivered_web_notifications_count) > 0) {
          target.innerText = json.undelivered_web_notifications_count
          target.classList.remove('opaque')
        } else {
          target.classList.add('opaque')
        }
      })
    } catch(error) {
      console.log('User undelivered notifications check failed')
    }

    this.timeout = setTimeout(
      this.updateUndeliveredWebNotificationsCount.bind(this),
      5000
    )
  }
}
