import { Controller } from 'stimulus'

export default class extends Controller {
  static buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-page-break" title="Insert Page Break" tabindex="-1">Insert Page Break</button>'

  connect() {
    if (this.element.initialized) {
      this.setup()
    } else {
       this.element.addEventListener('trix-initialize', () => this.setup())
    }
  }

  setup() {
    const toolbar = document.getElementById(this.element.getAttribute('toolbar'))

    const buttonGroup = toolbar.querySelector('.trix-button-group--text-tools')
    buttonGroup.insertAdjacentHTML('beforeend', this.constructor.buttonHTML)

    const button = buttonGroup.querySelector('.trix-button--icon-page-break')

    button.addEventListener('click', this.click.bind(this))
  }

  click(ev) {
    ev.preventDefault()

    const detail = {
      type: 'attachment',
      content: {
        content: '<div class="page-break"></div>',
        contentType: 'page-break'
      }
    }

    const insertEvent = new CustomEvent('trix-template:insert', { detail: detail, bubbles: true })
    this.element.dispatchEvent(insertEvent)
  }
}
