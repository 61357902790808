import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['img', 'progress']

  connect() {
    this.preview = false

    document.addEventListener(`upload:progress:${this.data.get('id')}`, (ev) => this.progressListener(ev))
    document.addEventListener(`upload:success:${this.data.get('id')}`, (ev) => this.successListener(ev))
  }

  disconnect() {
    document.removeEventListener(`upload:progress:${this.data.get('id')}`, (ev) => this.progressListener(ev))
    document.removeEventListener(`upload:success:${this.data.get('id')}`, (ev) => this.successListener(ev))
  }

  async progressListener(event) {
    if (this.preview == false) {
      try {
        const dataUrl = await this.readFileAsDataUrl(event.detail.file)
        this.imgTarget.setAttribute('src', dataUrl)
        this.preview = true
      } catch(error) {
        console.error(error)
      }
    }

    const progress = (event.detail.data.loaded / event.detail.data.total) * 100
    this.progressTarget.setAttribute('value', Math.min(100, progress))
  }

  successListener() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.completed()
  }

  completed() {
    this.element.classList.remove('uploading')
    this.imgTarget.setAttribute('src', this.data.get('url'))
  }

  readFileAsDataUrl(file) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader()

      reader.addEventListener('load', (readerEv) => {
        resolve(readerEv.target.result)
      })

      reader.readAsDataURL(file)
    })
  }
}
