import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', () => {
      this.broadcast()
    })

    this.broadcast()
  }

  broadcast() {
    const event = new CustomEvent('onboarding:property:check', {
      detail: { checked: this.element.checked, slug: this.data.get('slug') }, bubbles: true
    })

    this.element.dispatchEvent(event) // Keep this semicolon
  }
}
