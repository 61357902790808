import Color from '../models/color'

// Array of arrays, representing rgb
const colors = [
  [48, 151, 209],  // #3097D1
  [32, 105, 146],  // #206992
  [109, 182, 223], // #6db6df
  [23, 75, 104],   // #174b68
  [151, 203, 232], // #97cbe8
  [14, 45, 63],    // #0e2d3f
  [67, 161, 213],  // #43a1d5
  [192, 224, 241], // #c0e0f1
  [5, 15, 21],     // #050f15
  [42, 135, 188],  // #2a87bc
  [234, 245, 250]  // #eaf5fa
]

export default function ColorMap(index) {
  let i = index

  while (i > colors.length - 1) {
    i = i - colors.length - 1
  }

  return new Color(...colors[i])
}
