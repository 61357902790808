import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'warning' ]

  static values = {
    warning: Number,
  }

  connect() {
    this.toggleWarning()
  }

  toggleWarning() {
    if (this.hasInputTarget && this.inputTarget.value.length > this.warningValue) {
      this._show()
      return
    }

    this._hide()
  }

  _show() {
    if (this.hasWarningTarget) {
      this.warningTarget.removeAttribute('hidden')
    }
  }

  _hide() {
    if (this.hasWarningTarget) {
      this.warningTarget.setAttribute('hidden', 'true')
    }
  }
}

