// Check the a checkbox or radio when an input changes
// and the input has a value
 // controller-el
 //   input type='checkbox' id='cb'
 //   label for='cb'
 //     MyLabel
 //     input type='text' data-action='nested-check#check'

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['check']

  check(ev) {
    this.checkTarget.checked = ev.currentTarget.value.length > 0
  }
}
