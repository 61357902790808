import { Controller } from 'stimulus'

export default class extends Controller {
  reload(ev) {
    const url = this.url

    url.searchParams.delete(ev.target.name)
    url.searchParams.append(ev.target.name, ev.target.value)

    Turbolinks.visit(url.toString(), { action: 'replace' })
  }

  get url() {
    return new URL(window.location)
  }
}
