import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('error', () => this.error())
  }

  error() {
    const parent = this.element.parentElement

    if (this.element.src != this.data.get('alt')) {
      this.element.src = this.data.get('alt')

      if (parent.tagName == 'A') {
        parent.href = this.data.get('alt')
      }
    } else {
      this.element.remove()

      if (parent != null) { parent.remove() }
      console.error(`Invalid Upload: ${this.data.get('alt')}`)
    }
  }
}
