export default function(file, fields) {
  const data = new FormData()

  if (fields) {
    Object.keys(fields).forEach((key) => {
      data.append(key, fields[key])
    })
  }

  data.append('file', file, fields.key)

  return data
}
