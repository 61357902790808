import { Controller } from 'stimulus'
import { render } from 'timeago.js'

export default class extends Controller {
  static values = { removeAgo: Boolean }

  connect() {
    this.toggled = true
    this.element.addEventListener('click', this.toggle.bind(this))
    this.toggle()
  }

  toggle() {
    if (this.toggled) {
      render(this.element)

      if (this.hasRemoveAgoValue) {
        this.element.innerText = this.element.innerText.replace(' ago', '')
      }
    } else if(this.element.title) {
      this.element.innerText = this.element.title
    }

    this.toggled = !this.toggled
  }
}
