import { Controller } from 'stimulus'

// Add the disabled attribute to
export default class extends Controller {
  static targets = ['quarry']

  connect() {
    this.observer = new MutationObserver(() => this.mutation())
    this.observer.observe(this.element, this.config)
  }

  disconnect() {
    this.observer.disconnect()
  }

  mutation() {
    if (this.element.querySelectorAll('.pending').length >= 1) {
      this.quarryTargets.forEach((quarry) => {
        quarry.setAttribute('disabled', true)
      })
    } else {
      this.quarryTargets.forEach((quarry) => {
        quarry.removeAttribute('disabled')
      })
    }
  }

  get config() {
    return {
      attributes: true, childList: false, subtree: true,
      attributeFilter: ['class']
    }
  }
}
