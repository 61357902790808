import { Controller } from 'stimulus'
import { Map, TileLayer, Marker, LatLngBounds } from 'leaflet'

export default class extends Controller {
  static values = {
    mapboxToken: String,
    markerData: Array,
    enableZoom: Boolean,
    zoom: Number
  }

  connect() {
    const bounds = this.markerDataValue.reduce((bounds, data) => {
      return bounds.extend(data.location)
    }, new LatLngBounds())

    this.map = new Map(this.element, {
      center: bounds.getCenter(), zoom: this.zoomValue, zoomControl: this.enableZoomValue,
      zoomAnimation: true, fadeAnimation: true
    })

    this.map.fitBounds(bounds)

    new TileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/streets-v11',
        accessToken: this.mapboxTokenValue
      }
    ).addTo(this.map)


    this.markerDataValue.forEach((data) => {
      this.addMarker(data.location, data.label, data)
    })
  }

  addMarker(position, title, _data) {
    const marker = new Marker(position, {
      title: title
    })

    marker.addTo(this.map)
  }
}
