import { Controller } from 'stimulus'
import { v4 as uuid } from 'uuid'

import Upload from '../../lib/upload'
import { createMarkupEditorToolStyles, createMarkupEditorToolStyle, createDefaultColorOptions } from '../../lib/pintura'

export default class extends Controller {
  static targets = ['input', 'img', 'link']

  remove(ev) {
    ev.preventDefault()
    this.imgTarget.remove()
    this.inputTarget.value = 'remove'
    this.element.setAttribute('hidden', true)
  }

  edit(ev) {
    ev.preventDefault()

    this.element.classList.add('pending')

    const detail = {
      src:                    this.data.get('url'),
      util:                   'annotate',
      annotateActiveTool:     'arrow',
      markupColor:            '#f44336',
      markupEditorToolStyles: createMarkupEditorToolStyles({
        arrow: createMarkupEditorToolStyle('line', {
          strokeWidth: '1.25%',
          lineEnd:     'arrow-solid',
          lineStart:   'none',
          strokeColor: createDefaultColorOptions().red
        }),
        ellipse: createMarkupEditorToolStyle('ellipse', {
          backgroundColor: createDefaultColorOptions().transparent,
          strokeWidth:     '0.75%',
          strokeColor:     createDefaultColorOptions().red
        }),
        rectangle: createMarkupEditorToolStyle('rectangle', {
          backgroundColor: createDefaultColorOptions().transparent,
          strokeWidth:     '1.25%',
          strokeColor:     createDefaultColorOptions().red
        }),
        text: createMarkupEditorToolStyle('text', {
          fontSize:     '8%',
          color:     createDefaultColorOptions().red
        })
      })
    }

    const event = new CustomEvent('photo-editor:edit', { bubbles: true, detail: detail })
    this.element.dispatchEvent(event)

    this.element.addEventListener('photo-editor:done', (ev) => {
      if (ev.detail.file) {
        this.persist(ev.detail.file).then(() => this.done())
      } else { this.done() }
    }, { once: true })
  }

  done() {
    this.element.classList.remove('pending')
  }

  updateProgress(_ev) {}

  complete(uploadData) {
    this.inputTarget.value = uploadData.id
    this.imgTarget.src = uploadData.imgUrl
    this.linkTarget.href = uploadData.url

    this.imgTarget.addEventListener('load', () => {
      this.element.classList.remove('pending')
    })
  }

  async persist(file) {
    const attrs = this.attrs
    const presignUrl = `${this.data.get('presignUrl')}` +
                         `?filename=${attrs.filename}&size=${file.size}&mime_type=${file.type}` +
                         `&t=${uuid()}`

    const upload = new Upload(this.data.get('csrfToken'), {}, { meta: attrs.meta })
    upload.on('upload:progress', (ev) => this.updateProgress(ev))

    const uploadData = await upload.create(presignUrl, file)
    this.complete(uploadData)
  }

  get attrs() {
    return JSON.parse(this.data.get('attrs'))
  }
}
