import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['view', 'viewActionsCompleted', 'viewActionsIncomplete']

  connect() {
    setTimeout(() => this.checkCompleted(), 1000)
  }

  checkCompleted() {
    fetch(this.data.get('path')).then((response) => {
      if (response.status != 200) {
        setTimeout(() => this.checkCompleted(), 1000)
      } else {
        this.completed()
      }
    })
  }

  completed() {
    if (this.viewTarget.hidden) {
      this.viewTarget.remove()
    } else {
      this.viewActionsIncompleteTarget.setAttribute('hidden', true)
      this.viewActionsCompletedTarget.removeAttribute('hidden')
      this.viewTarget.classList.remove('fadeInRight', 'fadeInLeft')
      this.viewTarget.classList.add('bounce')
    }
  }
}
