import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['forms']

  submit(ev) {
    ev.preventDefault()

    this.formsTargets.forEach((form) => {
      const selected = form.querySelector('input[name="selected"][type="checkbox"]:checked')

      if (selected) {
        if (selected.checked) { Rails.fire(form, 'submit') }
      }
    })
  }
}
