import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    if (this.inputTarget.value == '#000000') {
      this.inputTarget.value = this.randomColor
    }
  }

  generate(ev) {
    ev.preventDefault()
    this.inputTarget.value = this.randomColor
  }

  get randomColor() {
    const hash = '0123456789ABCDEF'
    let hashColor = '#'
    for (let i = 0; i < 6; i++)
      hashColor += hash[Math.floor(Math.random() * hash.length)]

    return hashColor
  }
}
