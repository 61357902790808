export default function() {
  const observer = new MutationObserver((mutations) => {
    for (var i = mutations.length - 1; i >= 0; i--) {
      const el = mutations[i].target

      if (el.dataset.toggle == 'popover') {
        window.jQuery(el).popover()
      }
    }
  })

  observer.observe(document, {
    attributes: false,
    childList: true,
    subtree: true
  })
}
