import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.hideToolbars()

    this.element.addEventListener('trix-focus', (ev) => {
      ev.target.toolbarElement.removeAttribute('invisible')
      this.hideToolbars(ev.target.toolbarElement)
    })
  }

  hideToolbars(excluded) {
    [].forEach.call(this.element.querySelectorAll('trix-toolbar'), (toolbar) => {
      if (toolbar != excluded) {
        toolbar.setAttribute('invisible', true)
      }
    })
  }
}
