import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['propertySelect']

  split(ev) {
    ev.preventDefault()

    this.propertySelectTargets.forEach((propertySelect) => {
      const streetNumber = parseInt(propertySelect.getAttribute('data-streetNumber'))
      const even = streetNumber % 2 === 0 ? true : false

      if (even) {
        propertySelect.value = 'Even'
      } else {
        propertySelect.value = 'Odd'
      }

      const event = new Event('change')
      propertySelect.dispatchEvent(event)
    })
  }
}
