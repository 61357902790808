import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'

import Locationable from '../../concerns/locationable'

export default class extends Locationable(Controller) {
  static tmpl = `<div class="signature-pad">
    <div class="signature-pad--actions">
      <div>
        <button type="button" class="button clear btn btn-link text-danger" data-action="signature-pad#clear"><i class="fal fa-times"></i> Clear</button>
        <button type="button" class="button btn btn-link" data-action="signature-pad#undo"><i class="fal fa-undo"></i> Undo</button>
      </div>
    </div>
    <div class="signature-pad--body">
      <canvas data-signature-pad-target="canvas" height="200"></canvas>
    </div>
    <div class="signature-pad--footer">
      <div class="description">Sign above</div>
      <br>
      <label class="text-dark" for="type-signature">Or Type Signature</label>
      <input type="text" id="type-signature" class="form-control" placeholder="Type Your Signature" data-action="signature-pad#type" data-signature-pad-target="textInput">
    </div>
  </div>`

  static targets = ['canvas', 'textInput', 'outputInput', 'metaInput']
  static values = { enabled: Boolean }

  connect() {
    this.element.insertAdjacentHTML('beforeend', this.constructor.tmpl)
    this.signaturePad = new SignaturePad(this.canvasTarget)

    this.signaturePad.addEventListener('beforeUpdateStroke', this.clearTextInput.bind(this))
    this.signaturePad.addEventListener('endStroke', this.updateOutput.bind(this))

    this.resize()
  }

  positionUpdate(ev) {
    this.metaInputTarget.value = JSON.stringify(this.positionAsObject(ev.detail.position))
  }

  positionError(ev) {
    console.log(ev)
  }

  undo() {
    const data = this.signaturePad.toData()

    if (data) {
      data.pop()
      this.signaturePad.fromData(data)
    }
    this.clearTextInput()
    this.updateOutput()
  }

  clear() {
    this.clearTextInput()
    this.signaturePad.clear()
    this.updateOutput()
  }

  clearTextInput() {
    if (this.typedText != '') {
      this.textInputTarget.value = ''
      this.signaturePad.clear()
    }
  }

  type() {
    const fontSize = 48
    const canvas = this.canvasTarget
    const ctx = canvas.getContext('2d')
    const x = canvas.offsetWidth / 2
    const y =(canvas.offsetHeight / 2) + (fontSize/2)

    this.signaturePad.clear()

    ctx.font = `${fontSize}px cursive`
    ctx.textAlign = 'center'
    ctx.fillText(this.typedText, x, y)
    this.updateOutput()
  }

  resize() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)
    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio
    this.canvasTarget.getContext('2d').scale(ratio, ratio)

    this.clearTextInput()
    this.updateOutput()
  }

  updateOutput() {
    if (this.enabledValue != false) {
      this.outputInputTarget.value = this.canvasTarget.toDataURL('image/png')
      this.listenForLocationChange(10000)
      this.broadcastEvents()
    }
  }

  broadcastEvents() {
    const eventName = this.blankCanvas ? 'formLock' : 'formUnLock'
    const event = new CustomEvent(eventName, { detail: { binary: true }, bubbles: true })
    this.element.dispatchEvent(event)
  }

  get blankCanvas() {
    const context = this.canvasTarget.getContext('2d')

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, this.canvasTarget.width, this.canvasTarget.height).data.buffer
    )

    return !pixelBuffer.some(color => color !== 0)
  }

  get typedText() {
    return this.textInputTarget.value
  }
}
