import FileFormData from './file_form_data'

export default class {
  constructor(csrfToken, data, params) {
    this.csrfToken = csrfToken
    this.data      = data || {}
    this.handlers  = []
    this.params    = params
  }

  create(presignUrl, file) {
    return new Promise((resolve, reject) => {
      let promiseChain

      if (this.data.resourceUrl == undefined) {
        promiseChain = this.presign(presignUrl)
      } else {
        this.emit('presign:success', this.data)
        promiseChain = Promise.resolve()
      }

      promiseChain.catch(reject).then(() => {
        return this.upload(file).catch((error) => { throw(error) })
      }).then(() => {
        return this.complete()
      }).catch((err) => { console.log(err); reject(err) }).then(resolve)
    })
  }

  presign(presignUrl) {
    return new Promise((resolve, reject) => {
      this.emit('presign:start')

      const presignXhr = new XMLHttpRequest()

      presignXhr.addEventListener('load', () => {
        this.emit('presign:complete')

        if (this.isHttpSuccess(presignXhr)) {
          this.data = JSON.parse(presignXhr.responseText)
          this.emit('presign:success', this.data)

          resolve(this.data)
        } else {
          this.emit('presign:failure', presignXhr)
          reject(new Error(presignXhr.responseText))
        }
      })

      presignXhr.addEventListener('timeout', () => { this.emit('presign:failure', presignXhr); reject(new Error('timeout')) })
      presignXhr.addEventListener('error', () => { this.emit('presign:failure', presignXhr); reject(new Error('error')) })
      presignXhr.addEventListener('abort', () => { this.emit('presign:failure', presignXhr); reject(new Error('abort')) })

      presignXhr.timeout = 30000
      presignXhr.open('POST', presignUrl, true)
      presignXhr.setRequestHeader('X-CSRF-Token', this.csrfToken)
      presignXhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

      if (this.params != null) {
        presignXhr.send(JSON.stringify(this.params))
      } else {
        presignXhr.send()
      }
    })
  }

  upload(file) {
    return new Promise((resolve, reject) => {
      const uploadXhr = new XMLHttpRequest()

      uploadXhr.addEventListener('load', () => {
        this.emit('upload:complete', this.data)

        if (this.isHttpSuccess(uploadXhr)) {
          this.validateUrlHttpSuccess(this.data, file.size).then(() => {
            this.emit('upload:success', this.data)
            resolve(this.data)
          }).catch(reject)
        } else {
          this.emit('upload:failure', this.data)
          reject(new Error(uploadXhr.responseText))
        }
      })

      uploadXhr.upload.addEventListener('progress', (progressEvent) => {
        Object.assign(this.data, {
          loaded: progressEvent.loaded, total: progressEvent.total
        })

        this.emit('upload:progress', this.data)
      })

      uploadXhr.addEventListener('timeout', (ev) => { this.emit('upload:failure', uploadXhr); console.error(ev); reject(new Error('timeout')) })
      uploadXhr.addEventListener('error', (ev) => { this.emit('upload:failure', uploadXhr); console.error(ev); reject(new Error('error')) })
      uploadXhr.addEventListener('abort', (ev) => { this.emit('upload:failure', uploadXhr); console.error(ev); reject(new Error('abort')) })

      uploadXhr.timeout = 400000
      uploadXhr.open('POST', this.data.upload_url, true)
      uploadXhr.send(FileFormData(file, this.data.fields))

      this.emit('upload:start')
    })
  }

  complete() {
    return new Promise((resolve, reject) => {
      const completionXhr = new XMLHttpRequest()

      completionXhr.addEventListener('load', () => {
        if (this.isHttpSuccess(completionXhr)) {
          this.emit('complete:success', this.data)
          resolve(this.data)
        } else {
          this.emit('complete:failure', completionXhr)
          reject(new Error(completionXhr.responseText))
        }
      })

      completionXhr.addEventListener('timeout', () => { this.emit('complete:failure', completionXhr); reject(new Error('timeout')) })
      completionXhr.addEventListener('error', () => { this.emit('complete:failure', completionXhr); reject(new Error('error')) })
      completionXhr.addEventListener('abort', () => { this.emit('complete:failure', completionXhr); reject(new Error('abort')) })

      completionXhr.timeout = 30000

      completionXhr.open('PUT', this.data.resourceUrl, true)
      completionXhr.setRequestHeader('X-CSRF-Token', this.csrfToken)
      completionXhr.send()
    })
  }

  on(eventName, handler) {
    (this.handlers[eventName] = this.handlers[eventName] || []).push(handler)

    return this
  }

  // PRIVATE API

  emit(eventName) {
    for (let list = this.handlers[eventName], i = 0; list && list[i];) {
      list[i++].apply(this, list.slice.call(arguments, 1))
    }
  }

  isHttpSuccess(xhr) {
    return (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304
  }

  validateUrlHttpSuccess(data, fileSize) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.addEventListener('readystatechange', (_ev) => {
        // Headers have been received
        if (xhr.readyState == 2) {
          const statusCode = xhr.status
          const contentLength = xhr.getResponseHeader('Content-Length')

          if (this.isHttpSuccess(xhr) && parseInt(contentLength) == fileSize) {
            xhr.abort()
            resolve()
          } else {
            console.log(`Upload verfiy: expected ${fileSize} got ${contentLength}`)

            xhr.abort()
            reject(new Error(`Status: ${statusCode}, content length: ${contentLength}`))
          }
        }
      })

      xhr.addEventListener('timeout', () => { reject(new Error('timeout')) })
      xhr.addEventListener('error', () => { reject(new Error('error')) })

      xhr.timeout = 60000

      if (data.headUrl) {
        xhr.open('HEAD', data.headUrl, true)
      } else {
        xhr.open('GET', data.url, true)
      }
      xhr.setRequestHeader('X-CSRF-Token', this.csrfToken)
      xhr.send()
    })
  }
}
