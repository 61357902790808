import { Controller } from 'stimulus'

export default class extends Controller {
  change(ev) {
    if (ev.target.classList.contains('changeTrigger')) {
      this.element.classList.add(this.data.get('change'))
    } else {
      this.element.classList.remove(this.data.get('change'))
    }
  }

  click() {
    this.element.classList.toggle(this.data.get('click'))
  }
}
